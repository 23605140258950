console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);

var elBtnMenu = document.querySelectorAll(".js-eventClick__header__menu"); 

elBtnMenu.forEach( function(element, index) {
  element.addEventListener("click", function(){
    document.getElementsByClassName('header__menu')[0].classList.toggle('is-active');
  });
});





// Top Scroll button element
// $('body').materialScrollTop();

// anchor link
// $(document).on('click', 'a[href^="#"]', function (event) {
//   event.preventDefault();

//   $('html, body').animate({
//     scrollTop: $($.attr(this, 'href')).offset().top
//   }, 500);
// });




